import React from 'react'
import Layout from '../components/layout'
import Seo from "../components/seo"


//Import Components
import PhiHero from "../components/UberUns/PhiHero"
import PhiFeatures from "../components/UberUns/PhiFeatures"

//Import CSS
import '../styles/App.scss'

const schema = {
    "@context": "http://schema.org",
    "@id": "https://www.augen-permanence.ch/uber-uns/",
    "@type": "LocalBusiness",
    "alternateName": "AP",
    "email": "info@augen-permanence.ch",
    "name" : "Augen Permanence",
    "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": ["Monday"],
          "opens": "08:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": ["Tuesday"],
          "opens": "08:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": ["Wednesday"],
          "opens": "08:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": ["Thursday"],
          "opens": "08:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": ["Friday"],
          "opens": "08:00:00"
        }
      ],
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Neubruchstrasse 15",
        "addressLocality": "Maur",
        "postalCode": "8127",
        "addressCountry": "CH"
      },  
    "url": "https://www.augen-permanence.ch",
    "image": "https://www.augen-permanence.ch/assets/augenpermanence-makula-amd.png" //OG
}

function philosophie() {
    return (
        <Layout>
        <Seo 
        title="Über uns" 
        description="Erfahren Sie wofür sich die Augen Permanence einsetzt und wie sie plant die Augengesundheit jetzt und in der Zukunft zu fördern und zu unterstützen."
        const={schema}
        />
        <div className="main-container">
            <PhiHero />
            <PhiFeatures />
        </div>
        </Layout>
    )
}

export default philosophie
