import React from 'react'
import { Parallax } from 'react-parallax';
//Import Images
import AugenGesundheit from '../../assets/augengesundheit-gesichtspflege-makula.jpg'
import Trendstoffe from '../../assets/keine-trendstoffe-augenpermanence.jpg'
import Lebensfreude from '../../assets/makula-zurich.jpeg'

function PhiFeatures() {
    return (
        <>
        <div className="phi-container">
            <div className="phi-image-container">
                <h2 className="phi-text-overlay">
                    Augengesundheit <br></br> & Gesichtspflege
                </h2>
                <Parallax bgImage={AugenGesundheit} strength={-100} className="phi-image" bgImageAlt="Die Nahrwerte von Makula. Beitrag zum Erhalt der normalen Sehkraft.">
                   <div style={{height: 400}}></div>
               </Parallax>
            </div>
            <div className="phi-text">
                <h3>
                Fokus auf Augengesundheit und Gesichtspflege
                </h3>
                <h4>
                Die Augen Permanence steht zu ihrem Namen und konzentriert sich auf die Entwicklung hochwertiger Produkte im Bereich der Augen und des Gesichts. Mit über 30 Jahren Erfahrung und täglicher Tätigkeit mit Patienten, welche an Augenproblemen leiden, haben wir uns zum Ziel gemacht, diese Leiden präventiv vorzubeugen.
                </h4>
            </div>
            <div className="phi-image-container">
                <h2 className="phi-text-overlay">
                    Keine <br></br> Trendstoffe
                </h2>
                <Parallax bgImage={Trendstoffe} strength={-100} className="phi-image" bgImageAlt="Die Nahrwerte von Makula. Beitrag zum Erhalt der normalen Sehkraft.">
                   <div style={{height: 400}}></div>
               </Parallax>
            </div>
            <div className="phi-text">
                <h3>
                Wir verwenden keine Trendstoffe
                </h3>
                <h4>
                Unsere Produkte enthalten keine Zutaten, welche sich nicht durch unsere jahrelange Forschung und die Zusammenarbeit mit Pfeilern in der Medizin als nützlich gezeigt haben. Uns ist es wichtig unseren Kunden Produkte zu offerieren, welche ihre Wirksamkeit bereits etliche Male bewiesen haben.
                </h4>
            </div>
            <div className="phi-image-container">
                <h2 className="phi-text-overlay">
                    Mehr Lebensfreude <br></br> durch Fortschritt
                </h2>
                <Parallax bgImage={Lebensfreude} strength={-100} className="phi-image" bgImageAlt="Die Nahrwerte von Makula. Beitrag zum Erhalt der normalen Sehkraft.">
                   <div style={{height: 400}}></div>
               </Parallax>
            </div>
            <div className="phi-text">
                <h3>
                Mehr Lebensfreude durch Fortschritt
                </h3>
                <h4>
                Jedes Jahr werden erstaunliche Fortschritte in der Augenmedizin gemacht. Wir möchten diese Fortschritte mit unseren Produkte für alle zugänglich machen.
                </h4>
            </div>
        </div>
        </>
    )
}

export default PhiFeatures
