import React from 'react'

//Import Video & Images
import About from '../../assets/about.mp4'

function PhiHero() {
    return (
        <div className="phi-hero-container">
            <video
            className="video-about"
            playsInline
            autoPlay
            muted
            webkit-playsinline="true"
            loop
            src={About}
            ></video>
            <div className="phi-hero-text">
                <h1>Unsere Philosophie</h1>
            </div>
        </div>
    )
}

export default PhiHero
